"use client";

// External
import clsx from "clsx";
import { usePathname } from "next/navigation";
import { MouseEvent, useContext } from "react";

// Internal
import { CameraContext } from "@/contexts/CameraContext";
import { MenuContext } from "@/contexts/MenuContext";
import ArrowRight from "@/public/images/arrow-right-large.svg";
import { columnsClass } from "@/utils/classes";

const BottomMenuLinks = () => {
  const pathname = usePathname();
  const { navigate } = useContext(CameraContext);
  const { menuItems } = useContext(MenuContext);
  const filteredMenuItems = menuItems
    .map((item, i) => ({ menuItemIndex: i, ...item }))
    .filter(
      (item) =>
        item.count && item.internalLink.slug !== pathname.replace("/", ""),
    );

  return (
    <section className="relative z-10 flex flex-col items-center bg-dark [&+section]:pt-0">
      {filteredMenuItems.map((item) => (
        <a
          key={item.id}
          className="group flex w-full items-center gap-x-8 border-t-4 border-white p-6 leading-none md:p-8"
          href={`/${item.internalLink.slug}`}
          onClick={(e: MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();

            navigate(item.menuItemIndex);
          }}
        >
          <span
            className={clsx(
              "font-heading text-xl leading-none group-hover:underline sm:text-xxl md:text-xxxl",
            )}
          >
            {item.title}
          </span>

          <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-white bg-white text-dark transition-colors duration-300 group-hover:bg-dark group-hover:text-white">
            {item.count}
          </span>

          <span className="flex h-10 w-10 items-center justify-center rounded-[4px] border-2 border-white transition-colors duration-300 group-hover:bg-white [&>svg]:-rotate-45 group-hover:[&_path]:fill-dark">
            <ArrowRight />
          </span>
        </a>
      ))}

      <div className="w-full border-t-4 border-white px-8">
        <div className={clsx(columnsClass, "!gap-y-6 py-8")}>
          <div className="flex w-full gap-x-2 md:w-4/12">
            <span className="flex h-8 w-8 items-center justify-center rounded-full bg-red font-heading font-black leading-none text-['1rem']">
              sb.
            </span>

            <span className="font-heading text-[2.25rem] leading-none">
              © 2024
            </span>
          </div>

          <div className="w-full md:w-8/12">
            <span className="font-heading text-[2.25rem] leading-none">
              Utrecht, Utrecht
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BottomMenuLinks;
