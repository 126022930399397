"use client";

// External
import Link from "next/link";
import { MouseEvent, useContext } from "react";

// Internal
import { CameraContext } from "@/contexts/CameraContext";
import { MenuContext } from "@/contexts/MenuContext";

// Siblings
import { ButtonProps } from "./types";

const buttonClass =
  "block w-fit rounded-full border-2 border-white bg-white px-8 py-6 font-heading text-dark transition-colors duration-300 hover:bg-dark hover:text-white";

const InternalLink = ({
  internalLink,
  label,
}: {
  internalLink: ButtonProps["internalLink"];
  label: ButtonProps["label"];
}) => {
  const { navigate } = useContext(CameraContext);
  const { menuItems } = useContext(MenuContext);
  const menuItemIndex = menuItems.findIndex(
    (menuItem) => menuItem.internalLink.slug === internalLink.slug,
  );

  return menuItemIndex === -1 ? (
    <Link className={buttonClass} href={`/${internalLink.slug}`}>
      {label}
    </Link>
  ) : (
    <a
      className={buttonClass}
      href={`/${internalLink.slug}`}
      onClick={(e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        navigate(menuItemIndex);
      }}
    >
      {label}
    </a>
  );
};

const Button = ({
  href,
  internalLink,
  label,
  linkType,
  target,
}: ButtonProps) => {
  return linkType === "internal" ? (
    <InternalLink internalLink={internalLink} label={label} />
  ) : (
    <a className={buttonClass} href={href} target={target}>
      {label}
    </a>
  );
};

export default Button;
