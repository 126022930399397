// External
import { PortableText } from "@portabletext/react";

// Internal
import Banner from "@/components/Banner";
import { BannerProps } from "@/components/Banner/types";
import BottomMenuLinks from "@/components/BottomMenuLinks";
import Button from "@/components/Button";
import { ButtonProps } from "@/components/Button/types";
import ContactForm from "@/components/ContactForm";
import Container from "@/components/Container";
import { ContainerProps } from "@/components/Container/types";
import Editorial from "@/components/Editorial";
import { EditorialProps } from "@/components/Editorial/types";
import CustomImage from "@/components/Image";
import { ImageProps } from "@/components/Image/types";
import Overview from "@/components/Overview";
import { OverviewProps } from "@/components/Overview/types";

// Siblings
import { BlockProps } from "./types";

const components = {
  types: {
    banner: ({ value }: { value: BannerProps }) => <Banner {...value} />,
    bottomMenuLinks: () => <BottomMenuLinks />,
    button: ({ value }: { value: ButtonProps }) => <Button {...value} />,
    contactForm: () => <ContactForm />,
    container: ({ value }: { value: ContainerProps }) => (
      <Container {...value} />
    ),
    customImage: ({ value }: { value: ImageProps }) => (
      <CustomImage {...value} />
    ),
    editorial: ({ value }: { value: EditorialProps }) => (
      <Editorial {...value} />
    ),
    overview: ({ value }: { value: OverviewProps }) => <Overview {...value} />,
  },
};

const Block = ({ blocks }: BlockProps) => {
  return <PortableText value={blocks} components={components} />;
};

export default Block;
