"use client";

// External
import { ChangeEvent, FormEvent, useState } from "react";

const Input = ({
  handleChange,
  id,
  name,
  placeholder,
  type,
  value,
}: {
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  id: string;
  name: string;
  placeholder: string;
  type: string;
  value: string;
}) => (
  <>
    <label htmlFor={id} className="sr-only">
      {placeholder.replace("*", "")}
    </label>

    <input
      className="w-full rounded-[4px] border border-gray-300 px-8 py-6 font-heading text-dark placeholder:text-dark focus:border-dark focus:outline-none"
      aria-required="true"
      data-cursor="link"
      id={id}
      name={name}
      onChange={handleChange}
      placeholder={placeholder}
      required
      type={type}
      value={value}
    />
  </>
);

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    tel: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<"pending" | "submitted" | "error">(
    "pending",
  );

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    setStatus("pending");

    try {
      const response = await fetch("/api/submit-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Error submitting form");
      }

      setStatus("submitted");
      setFormData({
        name: "",
        email: "",
        tel: "",
        message: "",
      });
    } catch (error) {
      console.error("Submission error:", error);

      setStatus("error");
    } finally {
      setIsLoading(false);
    }
  };

  const getSubmitButtonLabel = () => {
    switch (status) {
      case "submitted":
        return "Verzonden";
      case "error":
        return "Fout bij verzenden van formulier";
      case "pending":
      case "submitted":
        return isLoading ? "Verzenden..." : "Verzend";
      default:
        return "Verzend";
    }
  };

  return (
    <div className="w-full [&:not(:first-child)]:mt-12">
      <form className="[&>*:not(:last-child)]:mb-8" onSubmit={handleSubmit}>
        <Input
          handleChange={handleChange}
          id="name"
          name="name"
          placeholder="Naam*"
          type="text"
          value={formData.name}
        />

        <div className="flex w-full flex-col gap-y-8 sm:flex-row sm:gap-x-6">
          <Input
            handleChange={handleChange}
            id="email"
            name="email"
            placeholder="Email*"
            type="email"
            value={formData.email}
          />

          <Input
            handleChange={handleChange}
            type="tel"
            name="tel"
            id="tel"
            placeholder="Telefoonnummer*"
            value={formData.tel}
          />
        </div>

        <label htmlFor="message" className="sr-only">
          Message
        </label>

        <textarea
          className="w-full rounded-[4px] border border-gray-300 px-8 py-6 font-heading text-dark placeholder:text-dark focus:border-dark focus:outline-none"
          aria-required="true"
          data-cursor="link"
          id="message"
          name="message"
          onChange={handleChange}
          placeholder="Bericht*"
          required
          rows={3}
        />

        <button
          className="mt-6 block w-fit rounded-full border-2 border-white bg-white px-8 py-6 font-heading text-dark transition-colors duration-300 hover:bg-dark hover:text-white disabled:opacity-50"
          data-cursor="link"
          type="submit"
          disabled={isLoading}
        >
          {getSubmitButtonLabel()}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
