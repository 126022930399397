"use client";

// External
import { useProgress } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Vector3 } from "three";

// Internal
import Block from "@/components/Block";
import AsciiRenderer from "@/components/LogoScene/components/AsciiRenderer";
import Light from "@/components/LogoScene/components/Light";
import Model from "@/components/LogoScene/components/Model";
import { columnsClass, sectionClass } from "@/utils/classes";

// Siblings
import { useInView } from "react-intersection-observer";
import { BannerProps } from "./types";

const Banner = ({ content, model }: BannerProps) => {
  const { inView, ref } = useInView();
  const { progress } = useProgress();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (progress === 100) {
      setIsLoading(false);
    }
  }, [progress]);

  return (
    <section className={clsx(sectionClass, "bg-dark")}>
      <div className={clsx(columnsClass, "items-center !justify-start")}>
        <div className="w-full md:w-8/12">
          <Block blocks={content} />
        </div>

        <div className="w-full sm:w-8/12 md:w-4/12">
          <div
            ref={ref}
            className={clsx(
              "aspect-square overflow-visible transition-opacity duration-300",
              {
                "opacity-0": !inView || isLoading,
              },
            )}
          >
            <Canvas
              camera={{
                fov: 50,
                aspect: 1 / 1,
                far: 5,
              }}
              dpr={0.25}
              frameloop={inView ? "always" : "never"}
              shadows={true}
            >
              <Light />

              <Model
                path={`/models/${model}.glb`}
                position={new Vector3(0, 0, 3.2)}
              />

              <AsciiRenderer bgColor="#181818" />
            </Canvas>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
